import React from "react";
import SEO from "../components/seo";
import Chapter1 from "src/views/Chapter1";




const IndexPage = () => {
  return (
    <>

      <SEO title="Opowieść o poecie-żołnierzu" description="Jak rodziła się żywa do dziś opowieść o młodym i pięknym  poecie-żołnierzu, za życia uznanym za geniusza poezji. Co jeszcze napisałby Krzysztof Kamil Baczyński, gdyby przeżył wojnę?" image={require('images/seo/1.jpg')}/>
      <Chapter1 />
    </>
  );
};

export default IndexPage;
