import React from "react"
import AudioPlayer, { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { TitleContrast, BodyTextWrap } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter1 = () => {
  const articles = useArticles({ articleCategory: 9046 })
  const { list } = articles
  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <TitleContrast notGrid>{list[1].Title}</TitleContrast>
              <CustomGrid>
                <BodyTextWrap>{parse(list[1].Content)}</BodyTextWrap>
                <div>
                  <AudioPlayerGroup list={[list[2], list[3]]} />
                </div>
              </CustomGrid>
              <CustomGrid
                photos={[list[4], list[5]]}
                grid={GRIDS.p1}
                heightRatio={0.37}
                photoStyles={[{ backgroundPosition: "center 10%" }]}
              />
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast style={{ marginTop: 0 }}>
                    {list[6].Title}
                  </TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[6].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[7]]}
                  grid={GRIDS.p0}
                  heightRatio={0.64}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(8, 12)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <CustomGrid>
                <TitleContrast>{list[12].Title}</TitleContrast>
                <div />
                <BodyTextWrap style={{ marginBottom: "3rem" }}>
                  {parse(list[12].Content)}
                </BodyTextWrap>
                <BodyTextWrap style={{ marginBottom: "3rem" }}>
                  {parse(list[13].Content)}
                </BodyTextWrap>
                <AudioPlayerGroup list={list.slice(14, 20)} />
              </CustomGrid>
              <CustomGrid
                photos={[list[20], list[21]]}
                grid={GRIDS.p1}
                heightRatio={0.28}
              />
            </Section>
            <Section>
              <TitleContrast notGrid>{list[22].Title}</TitleContrast>
              <CustomGrid>
                <BodyTextWrap>{parse(list[22].Content)}</BodyTextWrap>
                <div>
                  <AudioPlayerGroup list={[list[23]]} />
                  <CustomGrid
                    photos={[list[24], list[25]]}
                    grid={GRIDS.p1}
                    heightRatio={0.37}
                  />
                </div>
              </CustomGrid>
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid>
                <AudioPlayerGroup list={[list[27]]} />
                <CustomGrid
                  photos={[list[28]]}
                  grid={GRIDS.p0}
                  styles={{ padding: "1rem 1rem" }}
                />
                <AudioPlayerGroup list={[list[29]]} />
                <CustomGrid
                  photos={[list[30]]}
                  grid={GRIDS.p0}
                  styles={{ padding: "1rem 1rem" }}
                />
                <AudioPlayerGroup list={[list[31]]} />
                <CustomGrid
                  photos={[list[32]]}
                  grid={GRIDS.p0}
                  styles={{ padding: "1rem 1rem" }}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid
                  photos={list.slice(33, 39)}
                  grid={GRIDS.p4}
                  heightRatio={0.52}
                  photoStyles={[
                    {},
                    {},
                    { backgroundPosition: "top" },
                    {},
                    { backgroundPosition: "top" },
                  ]}
                />
              </Group>
            </Section>
          </div>
          <NextChapter title="Miłość" href="/milosc" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter1
